import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Puppy = ({ puppy }) => {
  console.log("fff", puppy)
  return (
    <Link to={puppy.handle}>
      <div className="card mt-5 mb-5" style={{ width: "20rem" }}>
        <Img alt={"props.al"} fluid={puppy.img.childImageSharp.fluid} />
        <div className="card-body">
          <div
            className="numberCircle"
            style={{ color: puppy.available ? "blue" : "white" }}
          >
            {puppy.available ? "Available" : "Reserved"}
          </div>
          <h3
            className="card-title text-center font-weight-bold"
            style={{ color: "#cab683" }}
          >
            {puppy.name}{" "}
            {puppy.gender === "male" ? (
              <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
            ) : (
              <i class="fas fa-venus" style={{ color: "pink" }}></i>
            )}
          </h3>
        </div>
      </div>
    </Link>
  )
}

export default Puppy
