import React from "react"

import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Puppy from "../components/puppy"

const Puppies = ({ data }) => {
  const puppies = data.puppies.edges[0].node.frontmatter.litters[0].puppies
  console.log("fff", puppies)
  const [isOpen, setOpen] = React.useState(false)
  return (
    <Layout>
      <SEO title="Available Puppies" />

      <section className="pt-3" /*style={{ marginTop: 176 }}*/>
        <div className="container mx-auto">
          <h1 className="display-8 text-center text-uppercase pb-3">
            Available Puppies
          </h1>
          <div className="text-center">
            <button
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModalCenterTitle"
              onClick={e => {
                e.preventDefault()
                setOpen(true)

                // Track custom click
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category: "Learn More",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: "Learn About Our Process",
                })
              }}
            >
              Learn About Our Process
            </button>

            <Modal
              open={isOpen}
              onClose={() => setOpen(false)}
              center
              style={{ zIndex: 100 }}
            >
              <h2>Our Process</h2>
              <p>
                Our process consists on depositing $300 to reserve a puppy. The
                deposit is refundable should something happen to the puppy while
                in our care or if the vet declares the puppy is not healthy
                while in our care. The deposit is nonrefundable if the family
                changes their mind. The family will receive an invoice. In order
                to create an invoice we need your first name, last name,
                address, and email.
              </p>
              <p>
                All puppies come with a spay/neuter contract. The spay/neuter
                contract must be signed before the puppy is picked up. If the
                family does not sign the contract before pick up we reserve the
                right to not refund the deposit and find the puppy a new home.
                Families will be held responsible for keeping their side of the
                contract. Sunny Doodles AZ will request spay/neuter certificates
                to be emailed to us when the puppies are of age. Failure to
                provide proof of spay/neuter will result in legal action taken.
              </p>
              <p>
                All puppies will be expected to be picked up within a week after
                they're eligible to go home. Sunny Doodles AZ will schedule pick
                up days with each family. Families are expected to arrive at the
                planned scheduled day to pick up their puppies.
              </p>
              <p>
                On pick up day the remaining balance of the puppy is due. Our
                puppies will not be able to go to their new home unless the
                remaining balance is paid. The remaning balance can be paid at
                anytime prior to pickup.
              </p>
            </Modal>
          </div>
        </div>
        <div className="container">
          <div className="row d-flex justify-content-around">
            {puppies.map(puppy => (
              <Puppy puppy={puppy} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Puppies

export const query = graphql`
  query {
    puppies: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            litters {
              puppies {
                handle
                name
                gender
                available
                img {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
